<template>
  <div class="main-container">
    <van-nav-bar fixed :title="feeType" left-arrow @click-left="onClickLeft" />
    <div class="task flexBox" @click="goTaskDetail">
      <div class="taskName">{{ positionName }}</div>
      <div class="icon" v-if="showDetail === 1">
        <van-icon name="arrow" />
      </div>
    </div>

    <div class="topBox flexBox">
      <div class="boxItem">
        <div class="value">{{ reportDate }}</div>
        <div class="label">所属月份</div>
      </div>
      <div class="line"></div>
      <div class="boxItem">
        <div class="value">{{ fee | money }}</div>
        <div class="label">{{ feeType }}(元)</div>
      </div>
    </div>

    <div class="listTitle">任务明细</div>

    <div class="message-tips">
      <van-icon class="warning" name="warning" />
      <div>任务审核通过之后才能拿到服务费哦!</div>
    </div>

    <div class="listWrap" :style="{ height }">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="this.list.length === 0 ? '' : '已加载全部'"
        @load="getList"
      >
        <div v-for="item in list" :key="item.id" @click="toDetails(item)">
          <div class="listItem">
            <div class="cardBox">
              <div class="title">
                <div class="tag warningB" v-if="item.taskClass == 2">B</div>
                <div class="tag warningP" v-else>P</div>
                <span>{{ item.taskName }}: {{ item.taskSubName }}</span>
              </div>

              <div class="bottom flexBox">
                <div class="left">
                  <div>{{ computedTime(item.reportTime) }}</div>
                  <div v-if="item.reportPrice !== null">任务价值 : {{ item.reportPrice | money }} 元</div>
                </div>
                <div class="right" :style="{ color: colorMap[item.auditStatus] }">
                  <div v-if="item.reportPrice !== null">
                    +
                    {{ item.auditStatus === 0 || item.auditStatus === 2 ? '0.00' : item.reportPrice | money }}
                    元
                  </div>
                  <div>{{ statusMap[item.auditStatus] }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-if="this.list.length === 0">
          <div style="color: #ccc; font-size: 12px; text-align: center">
            <div style="font-size: 14px">没有找到任何记录</div>
            <div>试试查询其他的吧</div>
          </div>
        </van-empty>
      </van-list>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { myTask } from '@/api/charge.js'
const statusMap = {
  0: '待审核',
  1: '审核通过',
  2: '审核不通过',
  3: '甄别结束'
}

const colorMap = {
  0: '#333333',
  1: '#52C41A',
  2: '#FAAD14',
  3: '#FAAD14'
}
export default {
  data() {
    return {
      statusMap,
      colorMap,

      taskBatchCode: [],
      type: '', // basic/temp
      fee: '',
      positionName: '',
      showDetail: '',
      reportDate: '',
      list: [], // 任务list

      loading: false, // 加载中
      finished: false, // 加载结束
      pageNum: 1,
      pageSize: 10,
      total: 0, // 总数量

      height: 0
    }
  },
  created() {
    const { type, fee, taskBatchCode, positionName, showDetail, reportDate } = this.$route.query
    this.type = type
    this.fee = fee
    this.positionName = positionName
    this.showDetail = +showDetail
    this.reportDate = reportDate
    if (taskBatchCode) {
      if (Array.isArray(taskBatchCode)) {
        this.taskBatchCode = taskBatchCode
      } else {
        this.taskBatchCode = [taskBatchCode]
      }
    } else {
      this.taskBatchCode = []
    }

    this.height = document.documentElement.clientHeight - 270 + 'px'
  },
  mounted() {
    this.getList()
  },
  computed: {
    feeType() {
      const feeTypeMap = {
        basic: '基础服务费',
        temp: '临时任务服务费'
      }
      return feeTypeMap[this.type]
    }
  },
  methods: {
    onClickLeft() {
      location.href = 'javascript:history.back();'
      // Toast("返回");
    },
    onClickRight() {
      // Toast("按钮");
    },
    toDetails(item) {
      this.$store.commit('auditDetailsItem', item)
      this.$router.push(`/auditDetails/${item.id}`)
    },
    // 获取列表   list懒加载
    getList(screen) {
      this.finished = true
      const { projectCode, reportDate } = this.$route.query
      myTask({
        projectCode,
        reportDate: reportDate + '-01',
        taskBatchCode: this.taskBatchCode,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then((res) => {
        this.pageNum++
        this.total = res.data.total

        if (screen) {
          this.list = res.data.data
          // this.pageSize = 10
        } else {
          this.list = [...this.list, ...res.data.data]
          // this.pageSize = 20
          // this.list = [...this.list, ...res.data.data,...this.list]
        }
        // 加载状态结束
        this.loading = false
        // 数据全部加载完成
        // console.log(this.total, this.list.length)
        this.finished = false
        if (this.list.length >= this.total || res.data.data.length === 0) {
          this.finished = true
        }
      })
    },

    // 计算每一条的日期
    computedTime(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    },

    checkState() {
      this.pageNum = 1
      this.getList(true)
    },

    goTaskDetail() {
      const { projectCode, terminalCode } = this.$route.query
      this.$router.push({
        path: '/taskDetail',
        query: {
          projectCode,
          terminalCode,
          taskBatchCode: this.taskBatchCode.length ? this.taskBatchCode[0] : ''
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.main-container {
  padding: 44px 0 0;
  overflow: hidden;
}
.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task {
  height: 44px;
  background-color: #fff;
  border-bottom: 1px solid #ececec;
  padding: 0 15px;
}
.taskName {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.topBox {
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 10px;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  .boxItem {
    text-align: center;
    flex: 1;
    .value {
      font-size: 18px;
      color: #0099ff;
    }

    .label {
      margin-top: 10px;
      font-size: 14px;
      color: #666;
    }
  }

  .line {
    height: 30px;
    border-left: 1px solid #ececec;
    flex-shrink: 0;
  }
}

.listTitle {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  padding: 0 15px;
}

.message-tips {
  display: flex;
  padding: 10px 15px;
  overflow: hidden;
  font-size: 14px;
  color: #ff9933;

  .warning {
    margin: 2px 5px 0 0;
  }
}
.listWrap {
  margin-top: 10px;
  overflow-y: auto;
}

.listItem {
  background: #fff;
  padding: 0 4%;
  box-shadow: inset 0px -1px 0px 0px rgba(237, 237, 237, 1);
}
.cardBox {
  padding: 15px 0 20px 0;
  color: #666;
  font-size: 14px;
  .title {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .tag {
    width: 18px;
    height: 18px;
    color: #ffffff;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    border-radius: 2px;
    margin-right: 10px;
  }
  .warningB {
    background: #1890ff;
  }
  .warningP {
    background: #52c41a;
  }

  .bottom {
    line-height: 24px;
    .right {
      font-size: 14px;
    }
  }
}
</style>
